<template>
  <svg 
    viewBox="0 0 127 77" 
    xmlns="http://www.w3.org/2000/svg" 
    title="Solmate Socks"
    aria-label="Solmate Socks Logo" 
    aria-hidden="true"
    class=" fill-current stroke-current hover:fill-current hover:stroke-current cursor-pointer"
  >
    <path d="M35.53,70.186 C33.635,69.868 33.147,69.639 33.157,69.075 C33.159,68.967 33.203,68.872 33.288,68.794 C33.459,68.636 33.881,68.464 34.921,68.527 C35.806,68.583 36.708,68.783 37.219,69.036 L37.321,69.086 C37.872,69.361 38.236,69.541 38.533,69.131 C38.668,68.944 38.997,68.485 39.132,68.259 C39.224,68.105 39.505,67.634 39.033,67.312 C37.782,66.456 36.525,66.091 34.83,66.091 C31.977,66.091 30.341,67.324 30.341,69.473 C30.341,71.817 32.671,72.251 34.727,72.635 L34.808,72.65 C36.818,73.026 37.079,73.554 37.056,73.836 C37.023,74.238 36.709,74.488 35.249,74.488 C34.061,74.488 33.06,74.052 32.321,73.672 C32.001,73.508 31.464,73.231 31.149,73.695 L31.063,73.82 C30.866,74.107 30.736,74.297 30.557,74.635 C30.16,75.382 30.716,75.71 30.983,75.868 C31.806,76.354 33.147,77 35.289,77 C38.378,77 39.858,75.958 39.946,73.721 C40.02,71.853 38.575,70.696 35.53,70.186"></path>
    <path d="M51.717,71.554 C51.717,69.849 50.73,68.978 49.326,68.978 C47.922,68.978 46.959,69.849 46.959,71.554 C46.959,73.272 47.91,74.154 49.349,74.154 C50.777,74.154 51.717,73.237 51.717,71.554 Z M43.828,71.566 C43.828,68.514 46.327,66.273 49.338,66.273 C52.349,66.273 54.847,68.312 54.847,71.485 C54.847,74.564 52.605,76.913 49.27,76.913 C45.962,76.913 43.828,74.618 43.828,71.566 L43.828,71.566 Z"></path>
    <path d="M58.793,71.566 C58.793,68.514 60.953,66.273 64.126,66.273 C66.134,66.273 67.289,67.002 68.085,68.118 C68.435,68.608 68.508,69.033 68.126,69.265 C67.824,69.447 67.28,69.801 67.147,69.881 C66.847,70.063 66.447,70.251 66.193,69.934 C65.706,69.326 65.116,68.956 64.167,68.956 C62.835,68.956 61.855,70.134 61.855,71.553 C61.855,72.971 62.835,74.163 64.194,74.163 C64.971,74.163 65.703,73.827 66.258,73.328 C66.81,72.831 67.125,72.697 67.507,73.02 C67.73,73.208 68.217,73.767 68.438,74 C68.836,74.42 68.547,74.813 68.306,75.092 C67.479,76.049 65.963,76.913 64.194,76.913 C60.67,76.913 58.793,74.618 58.793,71.566" id="Fill-5"></path>
    <path d="M80.791,66.462 C81.052,66.462 81.692,66.462 82.223,66.462 C82.67,66.462 82.865,66.892 82.464,67.306 C82.161,67.62 80.357,69.499 79.538,70.346 C79.272,70.621 79.239,70.977 79.505,71.318 C79.711,71.583 82.088,74.597 83.185,76.005 C83.501,76.411 83.364,76.67 82.905,76.67 C82.613,76.67 81.306,76.698 80.683,76.698 C80.326,76.698 79.902,76.491 79.657,76.174 C78.904,75.203 77.537,73.418 77.356,73.184 C77.117,72.874 76.853,72.871 76.668,73.062 C76.432,73.305 76.358,73.361 76.256,73.467 C76.072,73.657 75.979,74.118 75.979,74.448 C75.979,74.661 75.979,75.522 75.979,76.107 C75.979,76.43 75.936,76.67 75.421,76.67 C75.147,76.67 74.032,76.67 73.605,76.67 C73.229,76.67 73.127,76.32 73.127,75.906 C73.127,75.562 73.127,69.283 73.127,67.158 C73.127,66.723 73.473,66.462 73.885,66.462 C74.359,66.462 75.126,66.462 75.354,66.462 C75.779,66.462 75.979,66.769 75.979,67.202 C75.979,68.17 75.979,69.366 75.979,69.597 C75.979,70.28 76.305,70.012 76.584,69.72 C76.792,69.503 78.399,67.786 79.325,66.817 C79.644,66.484 80.31,66.462 80.791,66.462"></path>
    <path d="M92.594,70.186 C90.699,69.868 90.211,69.639 90.221,69.075 C90.223,68.967 90.267,68.872 90.352,68.794 C90.523,68.636 90.946,68.464 91.985,68.527 C92.87,68.583 93.772,68.783 94.284,69.036 L94.385,69.086 C94.937,69.361 95.3,69.541 95.597,69.131 C95.732,68.944 96.061,68.485 96.196,68.259 C96.288,68.105 96.569,67.634 96.098,67.312 C94.846,66.456 93.589,66.091 91.894,66.091 C89.041,66.091 87.405,67.324 87.405,69.473 C87.405,71.817 89.735,72.251 91.791,72.635 L91.872,72.65 C93.882,73.026 94.143,73.554 94.12,73.836 C94.088,74.238 93.774,74.488 92.313,74.488 C91.125,74.488 90.124,74.052 89.385,73.672 C89.065,73.508 88.529,73.231 88.213,73.695 L88.127,73.82 C87.93,74.107 87.8,74.297 87.621,74.635 C87.225,75.382 87.78,75.71 88.047,75.868 C88.87,76.354 90.211,77 92.353,77 C95.442,77 96.922,75.958 97.011,73.721 C97.084,71.853 95.64,70.696 92.594,70.186"></path>
    <path d="M88.716,56.872 C88.716,59.277 85.91,59.568 85.772,57.195 C85.643,54.985 86.89,54.342 87.65,54.469 C88.33,54.583 88.716,55.183 88.716,56.134 C88.716,56.41 88.716,56.199 88.716,56.872 Z M96.391,59.57 C95.982,58.932 95.736,57.986 95.649,57.011 C95.562,56.036 95.642,52.428 95.615,50.661 C95.589,48.895 94.947,46.717 93.078,45.695 C91.415,44.785 89.208,44.647 87.659,44.766 C85.14,44.958 82.905,46.025 81.495,47.669 C80.109,49.285 80.853,50.193 81.95,49.884 C83.258,49.515 85.122,49.209 86.798,49.721 C88.152,50.135 88.341,51.278 88.341,51.807 C88.341,52.193 88.181,52.158 87.698,52.186 C86.599,52.25 85.192,52.229 83.698,52.509 C82.203,52.789 80.435,53.546 79.654,55.438 C78.874,57.331 79.574,59.708 81.265,60.56 C82.956,61.413 85.651,61.39 87.239,60.991 C88.597,60.65 89.224,60.133 89.613,59.93 C90.023,59.715 90.376,59.799 90.593,60.087 C90.811,60.374 90.953,60.754 92.107,60.758 C93.261,60.762 95.371,60.758 96.04,60.758 C96.708,60.758 96.799,60.209 96.391,59.57 L96.391,59.57 Z" id="Fill-23"></path>
    <path d="M108.455,47.583 C108.455,48.141 108.317,48.382 107.709,48.382 C107.409,48.382 106.862,48.383 106.612,48.383 C105.936,48.383 105.527,48.836 105.511,49.514 C105.495,50.192 105.508,52.636 105.508,53.86 C105.508,54.984 105.727,55.613 106.339,56.077 C106.817,56.439 107.681,56.285 108.171,56.267 C108.66,56.25 109.262,56.608 108.663,57.677 C108.065,58.745 107.356,59.789 105.662,60.578 C104.411,61.159 102.386,61.441 100.598,60.933 C98.811,60.425 98.276,59.051 98.223,57.583 C98.17,56.115 98.232,54.879 98.257,50.789 C98.263,49.831 98.332,49.266 98.163,48.712 C97.928,47.942 97.553,47.785 96.894,47.397 C96.057,46.903 96.403,46.221 97.099,45.879 C97.794,45.536 98.936,45.06 99.67,44.287 C100.403,43.514 100.918,42.501 101.246,41.753 C101.574,41.004 101.863,41.038 102.466,41.051 C103.024,41.063 103.574,41.063 104.592,41.056 C105.429,41.051 105.691,41.524 105.699,42.115 C105.707,42.705 105.695,43.186 105.674,44.417 C105.66,45.221 105.872,45.543 106.747,45.56 C107.19,45.569 107.25,45.56 107.611,45.56 C108.383,45.56 108.455,45.948 108.455,46.479 L108.455,47.583"></path>
    <path d="M50.664,45.086 C50.039,45.086 49.459,45.179 49.465,46.023 C49.476,47.515 49.591,49.691 49.613,53.082 C49.636,56.473 49.437,58.333 49.428,59.165 C49.418,59.998 49.479,60.724 50.839,60.724 C52.199,60.724 54.18,60.724 55.342,60.724 C56.505,60.724 56.969,59.989 56.863,58.551 C56.757,57.113 56.694,53.896 56.878,52.781 C57.062,51.665 57.568,51.269 58.317,51.269 C59.067,51.269 59.411,51.966 59.487,52.818 C59.563,53.67 59.545,57.484 59.46,58.953 C59.385,60.25 59.529,60.724 60.498,60.724 C62.011,60.724 64.999,60.724 65.736,60.724 C66.474,60.724 66.975,60.574 66.935,59.178 C66.895,57.781 66.769,54.207 66.839,52.815 C66.909,51.424 67.451,50.512 68.363,50.564 C69.417,50.624 69.614,51.884 69.611,53.06 C69.608,54.236 69.531,58.047 69.52,59.224 C69.509,60.401 69.768,60.724 70.78,60.724 C71.791,60.724 76.4,60.724 77.271,60.724 C78.029,60.724 78.114,60.341 77.78,59.721 C77.445,59.102 76.97,58.236 76.924,56.589 C76.877,54.942 76.924,51.601 76.924,49.625 C76.924,47.648 75.979,44.72 72.62,44.693 C69.883,44.67 68.927,46.258 68.146,46.968 C67.229,47.801 66.634,47.311 66.093,46.589 C65.552,45.866 64.371,44.399 61.823,44.71 C59.276,45.02 58.246,46.861 57.679,47.705 C56.964,48.77 56,48.376 55.658,47.572 C55.279,46.683 55.013,45.948 54.582,45.554 C54.151,45.16 53.475,45.088 52.678,45.088 C51.882,45.088 50.664,45.086 50.664,45.086"></path>
    <path d="M118.674,50.694 C118.67,50.694 118.667,50.695 118.664,50.695 C117.639,50.863 117.336,50.307 117.334,49.681 C117.334,49.551 117.335,49.475 117.335,49.253 C117.335,49.03 117.356,48.097 117.474,47.431 C117.57,46.881 117.895,46.285 118.404,46.198 C118.9,46.113 119.255,46.512 119.48,46.987 C119.866,47.801 120.007,48.651 120.006005,49.458 C120.006005,50.163 119.695,50.523 118.674,50.694 Z M125.823,48.811 C125.644,48.354 124.7,45.349 120.229,44.713 C115.901,44.098 112.237,46.818 111.038,49.509 C109.745,52.41 109.971,56.672 112.392,58.981 C114.813,61.29 119.852,61.824 122.636,60.227 C124.884,58.938 125.749,56.939 125.851,56.213 C125.944,55.554 125.393,55.112 124.439,55.415 C123.485,55.719 121.407,56.119 119.735,55.489 C118.671,55.088 118.044,54.275 117.855,53.773 C117.666,53.272 117.591,52.606 118.666,52.506 C119.972,52.386 123.919,51.928 124.76,51.825 C125.558,51.727 125.985,51.649 126.157,51.028 C126.329,50.406 126.003,49.269 125.823,48.811 L125.823,48.811 Z" id="Fill-26"></path>
    <path d="M40.398,39.585 C39.672,39.585 39.377,39.693 39.39,40.696 C39.403,41.699 39.474,49.361 39.428,52.706 C39.382,56.051 39.326,57.089 39.3239874,58.048 C39.32,59.608 40.27,60.703 42.052,60.729 C44.159,60.759 46.132,60.77 46.859,60.768 C47.586,60.767 47.94,60.495 47.567,59.794 C47.194,59.092 46.897,58.477 46.727,57.261 C46.56,56.066 46.591,54.725 46.613,52.599 C46.641,49.894 46.758,42.145 46.785,40.978 C46.813,39.811 46.716,39.586 45.952,39.586 C45.188,39.586 41.124,39.585 40.398,39.585"></path>
    <path d="M29.815,54.402 C29.603,55.303 29.124,55.919 28.32,55.919 C27.516,55.919 27.037,55.303 26.824,54.402 C26.544,53.213 26.58,50.72 26.673,49.101 C26.766,47.483 27.466,46.638 28.32,46.638 C29.173,46.638 29.874,47.483 29.966,49.101 C30.059,50.72 30.095,53.213 29.815,54.402 Z M28.32,44.779 C24.929,44.779 19.304,46.11 19.304,53.151 C19.304,60.192 24.646,61.162 28.32,61.162 C31.995,61.162 37.137,59.581 37.338,53.151 C37.556,46.187 32.505,44.779 28.32,44.779 L28.32,44.779 Z"></path>
    <path d="M13.907,45.777 C14.956,45.621 16.629,45.178 16.958,43.669 C17.309,42.054 15.874,40.662 13.907,40.17 C11.309,39.519 7.145,39.272 3.859,41.091 C0.573,42.91 -0.084,46.09 0.307,48.219 C0.698,50.348 2.639,51.73 4.9,52.685 C6.437,53.333 8.766,54.317 9.834,54.919 C10.901,55.52 11.381,56.833 10.633,58.106 C9.886,59.379 7.658,59.452 6.466,58.316 C5.467,57.364 5.161,55.981 5.008,55.481 C4.819,54.864 4.46,54.523 3.478,54.682 C2.497,54.84 1.436,55.161 0.81,55.742 C0.183,56.323 -0.181,57.282 0.091,58.179 C0.364,59.076 1.133,59.834 2.686,60.384 C4.238,60.935 6.931,61.405 10.144,61.044 C13.356,60.684 16.665,58.726 17.505,55.789 C18.345,52.852 17.244,49.881 14.096,48.226 C11.176,46.691 8.511,45.96 7.493,45.036 C6.33,43.98 6.981,42.371 7.726,41.871 C8.932,41.06 10.532,41.26 11.535,42.562 C12.266,43.513 12.392,44.57 12.598,45.093 C12.874,45.789 13.498,45.837 13.907,45.777"></path>
    <path d="M47.371,32.694 L30.468,32.673 C30.468,32.673 30.358,29.589 30.875,27.976 C31.392,26.362 31.804,25.029 31.804,25.029 L47.869,30.284 L47.371,32.694"></path>
    <path d="M49.106,26.758 L33.112,19.607 C33.112,19.607 33.969,17.671 34.83,16.223 C35.691,14.776 37.193,12.917 37.193,12.917 L50.427,24.658 L49.106,26.758"></path>
    <path d="M53.105,21.806 L41.512,8.835 C41.512,8.835 42.735,7.517 44.331,6.476 C45.927,5.435 47.869,4.804 47.869,4.804 L55.107,20.241 L53.105,21.806"></path>
    <path d="M53.319,2.08 L58.872,18.903 L61.37,18.348 L60.275,0.781 C60.275,0.781 58.938,0.711 57.134,0.954 C55.33,1.197 53.319,2.08 53.319,2.08"></path>
    <path d="M65.539,18.039 L67.061,0.781 C67.061,0.781 67.994,0.867 69.59,1.075 C71.185,1.283 74.137,2.604 74.137,2.604 L67.927,18.679 L65.539,18.039"></path>
    <path d="M71.555,20.506 L79.878,4.231 C79.878,4.231 81.557,5.189 82.382,5.762 C84.089,6.947 85.899,8.768 85.899,8.768 L73.775,22.137 L71.555,20.506"></path>
    <path d="M76.362,24.753 L89.789,13.539 C89.789,13.539 91.71,15.274 92.473,16.731 C93.236,18.188 93.886,19.37 93.886,19.37 L77.931,27.094 L76.362,24.753"></path>
    <path d="M79.357,30.365 L95.692,25.382 C95.692,25.382 95.836,26.205 96.176,28.19 C96.58,30.545 96.319,32.694 96.319,32.694 L79.704,32.694 L79.357,30.365"></path>
    <path d="M76.362,32.694 L50.6,32.694 C50.6,32.694 52.344,25.347 57.599,23.096 C61.538,21.409 68.01,21.731 71.083,24.252 C74.865,27.354 76.362,32.694 76.362,32.694"></path>
  </svg>
</template>